
h1 {
    text-align: center;
}

p {
    line-height: 1.3em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

a {
    text-decoration: none;
}
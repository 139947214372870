.splash {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splash_text {
    text-align: center;
    max-width: 700px;
    font-size: 1.2em;
}
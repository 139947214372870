.header_main {
    left: 0;
    top: 0;
    right: 0;
    height: 3em;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    z-index: 100;
}

.header_main_bar {
    line-height: 3em;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
}

.header_main_bar_title {
    margin: 0 20px;
}

.header_main_bar_menu {
    margin: 0 20px;
    margin-left: auto;
}

a.header_main_bar_menu_item {
    padding: 0 10px;
    color: white;
    text-decoration: none;
}